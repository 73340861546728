<template>
  <section id="residents">
    <div class="row">
      <fieldset class="form-group">
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 :class="{ 'is-valid': transponder.isValid, 'is-invalid': transponder.isValid === false }"
                 placeholder="Enter transponder number"
                 v-model="transponder.value"
                 @blur="validateTransponder()"
          />
          <input type="text"
                 class="form-control"
                 :class="{ 'is-valid': registration.isValid, 'is-invalid': registration.isValid === false }"
                 placeholder="Enter registration number"
                 v-model="registration.value"
                 @blur="validateRegistration()"
          />
          <button class="btn btn-primary" type="button" :disabled="!formValidated || adding" @click="addResident()">Add Resident</button>
          <label v-if="error" class="control-label">{{error}}</label>
        </div>
      </fieldset>
    </div>
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Transponder</th>
                <th scope="col">Registration</th>
                <th scope="col">Added</th>
                <th scope="col">Removed</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="resident" v-for="resident in residents">
                <th scope="row">{{ resident.resident_id }}</th>
                <td>{{ resident.transponder }}</td>
                <td>{{ resident.registration }}</td>
                <td>{{ resident.created }}</td>
                <td>
                  <button type="button" class="btn btn-primary btn-sm" v-if="!resident.deleted" @click="removeResident(resident)">Remove</button>
                  {{ resident.deleted }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import APIService from "@/services/APIService";

export default {
  name: "Residents",
  data() {
    return {
      residents: [],
      filter: {},
      transponder: {
        value: null,
        error: null,
        isValid: undefined
      },
      registration: {
        value: null,
        error: null,
        isValid: undefined
      },
      error: null,
      adding: false
    }
  },
  computed: {
    formValidated() {
      return this.transponder.isValid && this.registration.isValid;
    }
  },
  methods: {
    validateTransponder: function() {
      if(!this.transponder.value || !/^[0-9A-Fa-f]{6}$/.test(this.transponder.value)) {
        this.transponder.isValid = false;
      } else {
        this.transponder.isValid = true;
        this.transponder.value = this.transponder.value.toUpperCase();
      }
    },
    validateRegistration: function() {
      if(!this.registration.value) {
        this.registration.isValid = false;
      } else {
        this.registration.isValid = true;
        this.registration.value = this.registration.value.toUpperCase();
      }
    },
    addResident: async function() {
      this.adding = true;
      await APIService.addResident(this.transponder.value, this.registration.value);
      this.transponder = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.registration = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.residents = await APIService.getResidents(this.filter);
      this.adding = false;
    },
    async removeResident(resident) {
      await APIService.deleteResident(resident.resident_id);
      this.residents = await APIService.getResidents(this.filter);
    }
  },
  async beforeCreate() {
    this.residents = await APIService.getResidents(this.filter);
  }
}
</script>

<style scoped>
tr.resident {
  line-height: 2.5;
}
</style>
